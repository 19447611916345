import React, { Fragment, useEffect, useId, useState } from "react";
import { requests } from "../axios";
import ScafTable from "./ScafTable";

const QuorumResults = ({ concept }) => {
  const headers = ["Inmueble", "QR", "Propietario"];

  const id = useId();

  const [answerState, setAnswerState] = useState({
    quorum: {},
  });

  const [apartmentState, setApartmentState] = useState({
    registered: {},
  });

  const [realQuorumState, setRealQuorumState] = useState({
    quorum: 0.0,
    quantity: 0,
    show: false,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, concept);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(intervalId);
  });

  const fetchData = async () => {
    const answerData = await Promise.all(
      concept[0].answers.map(async (answer) => {
        const data = {
          concept: answer.conceptId,
          jointId: answer.jointId,
          voteId: answer.answerId,
        };
        const coefficient = await requests.assembly.getCoefficient(data);
        const totalApartments = await requests.assembly.getTotalApartments(
          data
        );
        return {
          answerDescription: answer.answerDescription,
          coefficient: coefficient.data.toFixed(4),
          totalApartments: totalApartments.data,
        };
      })
    );
    answerData.sort((a1, a2) => a2.coefficient - a1.coefficient);
    setAnswerState({ quorum: answerData });
    await getApartmentsRegistered(answerData[0].totalApartments);
  };

  const getApartmentsRegistered = async (totalApartmentsRegistered) => {
    if (concept[0].conceptId === 1) {
      const response = await requests.assembly.getApartmentsRegistered({
        conceptId: concept[0].conceptId,
        jointId: concept[0].jointId,
      });
      const rows = response.data.map((apartment) => {
        return {
          Inmueble: apartment.apartmentId,
          QR: apartment.assemblyApartment.userId,
          Propietario: apartment.assemblyApartment.owner,
        };
      });
      setApartmentState({ registered: rows });
      setRealQuorum(response.data, totalApartmentsRegistered);
    }
  };

  const setRealQuorum = (apartmentsRegistered, totalApartmentsRegistered) => {
    const apartments = apartmentsRegistered.filter(
      (apartment) => apartment.assemblyApartment.userId !== null
    );
    const realQuorum = apartments
      .reduce(
        (sum, apartment) => sum + apartment.assemblyApartment.coefficient,
        0
      )
      .toFixed(4);
    const realQuantity = apartments.length;
    const showRealQuorum = realQuantity !== totalApartmentsRegistered;
    setRealQuorumState({
      quorum: realQuorum,
      quantity: realQuantity,
      show: showRealQuorum,
    });
  };

  const answersFragment =
    answerState.quorum.length &&
    answerState.quorum.map((answer, index) => {
      return (
        <Fragment key={`${id}-${index}`}>
          <h3
            className="quorum-answer-description"
            data-testid="answerDescription"
          >
            {answer.answerDescription}
          </h3>
          <h3
            className="quorum-answer-percentage"
            data-testid="answerPercentage"
          >
            {answer.coefficient} <span className="quorum-percentage-label">%</span>
          </h3>
          <h3 className="quorum-answer-total" data-testid="answerTotal">
            {answer.totalApartments} <span className="quorum-apartments-label">Inmuebles</span>
          </h3>
        </Fragment>
      );
    });

  const registerTemplate = (
    <>
      <section className="quorum-register-container" data-testid="register">
        <div className="quorum-answer-container">
          {answersFragment}
          {realQuorumState.show && (
            <>
              <h3
                className="quorum-answer-description"
                data-testid="answerDescription"
              >
                Quorum Actual
              </h3>
              <h3
                className="quorum-answer-percentage"
                data-testid="answerPercentage"
              >
                {realQuorumState.quorum} <span className="quorum-percentage-label">%</span>
              </h3>
              <h3 className="quorum-answer-total" data-testid="answerTotal">
                {realQuorumState.quantity} <span className="quorum-apartments-label">Inmuebles</span>
              </h3>
            </>
          )}
        </div>
      </section>
      <ScafTable headers={headers} rows={apartmentState.registered} />
    </>
  );

  const questionTemplate = (
    <>
      <h2
        className="title concept-description"
        data-testid="conceptDescription"
      >
        {concept[0].conceptDescription}
      </h2>
      <section className="quorum-answers-container" data-testid="answers">
        <div className="quorum-answer-container">{answersFragment}</div>
      </section>
      <section className="quorum-register-container" data-testid="total">
        <div className="quorum-answer-container">
          <h3
            className="quorum-answer-description"
            data-testid="answerTotalDescription"
          >
            Total
          </h3>
          <h3
            className="quorum-answer-percentage"
            data-testid="answerTotalPercentage"
          >
            {answerState.quorum.length
              ? answerState.quorum
                  .reduce(
                    (sum, answer) => sum + parseFloat(answer.coefficient),
                    0
                  )
                  .toFixed(4)
              : "0.0000"} <span className="quorum-percentage-label">%</span>
          </h3>
          <h3 className="quorum-answer-total" data-testid="answerTotalTotal">
            {answerState.quorum.length
              ? answerState.quorum.reduce(
                  (sum, answer) => sum + answer.totalApartments,
                  0
                )
              : "0"} <span className="quorum-apartments-label">Inmuebles</span>
          </h3>
        </div>
      </section>
    </>
  );

  return (
    <>{concept[0].conceptId === 1 ? registerTemplate : questionTemplate}</>
  );
};

export default QuorumResults;
